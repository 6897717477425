// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-fotokiosk-jsx": () => import("./../../../src/pages/fotokiosk.jsx" /* webpackChunkName: "component---src-pages-fotokiosk-jsx" */),
  "component---src-pages-galeria-jsx": () => import("./../../../src/pages/galeria.jsx" /* webpackChunkName: "component---src-pages-galeria-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-oferta-jsx": () => import("./../../../src/pages/oferta.jsx" /* webpackChunkName: "component---src-pages-oferta-jsx" */),
  "component---src-pages-regulamin-jsx": () => import("./../../../src/pages/regulamin.jsx" /* webpackChunkName: "component---src-pages-regulamin-jsx" */),
  "component---src-templates-galeria-template-jsx": () => import("./../../../src/templates/GaleriaTemplate.jsx" /* webpackChunkName: "component---src-templates-galeria-template-jsx" */)
}

